<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-divider></v-divider>
    <v-form ref="form" v-if="register" class="mt-1">
      <v-row justify="end">
        <v-col cols="12" md="4" class="text-right">
          <v-btn
            small
            dark
            class="mr-4"
            :color="$store.state.secondaryColor"
            @click="goBack"
          >
            <v-icon small left>close</v-icon>
            <span class="caption text-capitalize">{{ $t("cancel") }}</span>
          </v-btn>

          <v-btn
            small
            dark
            :color="$store.state.primaryColor"
            :loading="loading"
            @click="updateEmployee"
          >
            <v-icon small left>{{ $t("back") }}</v-icon>
            <span class="caption text-capitalize">{{ $t("save update") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <h2 class="title text-capitalize">
        {{ $t("update employee details") }}
      </h2>
      <small>*{{ $t("indicates a required field") }}</small>

      <v-divider class="my-2"></v-divider>
      <v-layout row wrap justify-space-between>
        <v-flex xs12 sm12 md6 lg6 class="pa-1">
          <div class="elevation-2">
            <v-card class="mx-auto mb-4" max-width="200">
              <v-img :src="register.avatar" contain></v-img>
            </v-card>

            <input type="file" @change="previewPicture" class="caption mt-2" />
          </div>
          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("firstname") }}*
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.names.first"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("middlename") }}
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.names.middle"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("lastname") }}*
                  </span>

                  <v-text-field
                    outlined
                    dense
                    v-model="register.names.last"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("gender") }}*
                  </span>
                  <v-radio-group
                    dense
                    v-model="register.gender"
                    :rules="rules.gender"
                    row
                  >
                    <v-radio value="male">
                      <template v-slot:label>
                        <div>M</div>
                      </template>
                    </v-radio>

                    <v-radio value="female">
                      <template v-slot:label>
                        <div>F</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("id-passport") }}*
                  </span>

                  <span></span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.employeeIdPassport"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("email") }}*
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.email"
                    :rules="rules.email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("telephone") }}*
                  </span>

                  <v-text-field
                    outlined
                    dense
                    v-model="register.telephone"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black">
                    {{ $t("business") }}
                  </span>
                  <v-select
                    outlined
                    dense
                    item-text="businessName"
                    item-value="id"
                    :items="ownersBusinesses"
                    v-model="register.businessId"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
        </v-flex>

        <v-flex xs12 sm12 md6 lg6 class="pa-1">
          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("employee role") }}>*
                  </span>

                  <v-select
                    dense
                    outlined
                    :items="roles"
                    v-model="register.role"
                    :rules="rules.emptyField"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("employee status") }}*
                  </span>
                  <v-text-field
                    dense
                    outlined
                    v-model="register.employeeStatus"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("employee shift") }}*
                  </span>
                  <v-autocomplete
                    outlined
                    dense
                    v-model="register.shiftId"
                    :items="shifts"
                    item-text="shiftName"
                    item-value="id"
                    @change="setShiftDetails(register.shiftId)"
                    :rules="rules.emptyField"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("country") }} *</span
                  >
                  <v-combobox
                    v-model="register.address.country"
                    :items="countries"
                    @change="selectCountry"
                    :rules="rules.emptyField"
                    outlined
                    dense
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("county-province") }}*</span
                  >
                  <v-combobox
                    outlined
                    dense
                    v-model="register.address.county"
                    :items="provinces"
                    @change="selectCounty"
                    :rules="rules.emptyField"
                  ></v-combobox>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>

          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("city-town") }}
                  </span>
                  <v-combobox
                    outlined
                    dense
                    :items="towns"
                    v-model="register.address.city"
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("district-territory") }}
                  </span>
                  <v-combobox
                    outlined
                    dense
                    v-model="register.address.territory"
                    :items="territories"
                  ></v-combobox>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>

          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black">
                    {{ $t("estate") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.address.estate"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black"
                    >{{ $t("street") }}
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.address.street"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black">{{
                    $t("building")
                  }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.address.building"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="caption font-weight-black">{{
                    $t("house-suite-number")
                  }}</span>
                  <v-text-field
                    dense
                    outlined
                    v-model="register.address.no"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12>
          <v-list dense class="pa-0">
            <v-list-item class="d-flex flex-wrap">
              <v-layout row wrap class="mt-3">
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("foreigner") }} :
                  </span>
                  <v-switch
                    v-if="register.employmentDocuments.length != 0"
                    :color="this.$store.state.secondaryColor"
                    disabled
                    :label="$t('clear files in order to switch')"
                  ></v-switch>

                  <v-switch
                    v-if="register.employmentDocuments.length == 0"
                    v-model="foreigner"
                  ></v-switch>
                  <v-layout row wrap>
                    <v-flex xs12 sm12 md12 lg12>
                      <v-card
                        class="mt-3"
                        max-width="300"
                        flat
                        tile
                        v-if="foreigner"
                      >
                        <v-list shaped>
                          <v-subheader>{{
                            $t("required documents")
                          }}</v-subheader>

                          <v-list-item-group
                            v-model="selectedForeignerItem"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in ForeignerItems"
                              :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon v-text="item.icon" small></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="text-capitalize body-2"
                                  v-text="item.text"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                      <v-card class="mt-3" max-width="300" flat tile v-else>
                        <v-list shaped>
                          <v-subheader>{{
                            $t("required documents")
                          }}</v-subheader>

                          <v-list-item-group
                            v-model="selectedLocalItem"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in LocalItems"
                              :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon v-text="item.icon" small></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="text-capitalize body-2"
                                  v-text="item.text"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1" v-if="foreigner">
                  <div v-if="selectedForeignerItem == 0">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("working visa") }}:
                    </span>
                    <v-file-input
                      v-model="workingVisa"
                      counter
                      show-size
                      @change="base64WorkingVisa"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedForeignerItem == 1">
                    <span class="font-weight-medium text-capitalize">CV: </span>
                    <v-file-input
                      counter
                      show-size
                      v-model="cvForeigner"
                      truncate-length="15"
                      @change="base64CV"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedForeignerItem == 2">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("file name") }}:
                    </span>

                    <v-form ref="others">
                      <v-text-field
                        outlined
                        dense
                        v-model="othersFileNameForeigner"
                      ></v-text-field>
                    </v-form>
                    <v-file-input
                      counter
                      v-model="othersForeigner"
                      show-size
                      @change="otherFile"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1 mt-3" v-else>
                  <div v-if="selectedLocalItem == 0">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("national Id") }} :
                    </span>
                    <v-file-input
                      counter
                      v-model="nationalId"
                      show-size
                      truncate-length="15"
                      @change="base64NationalId"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedLocalItem == 1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("cv") }}:
                    </span>
                    <v-file-input
                      counter
                      v-model="cvLocal"
                      show-size
                      @change="base64CV"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedLocalItem == 2">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("file name") }}File name:
                    </span>
                    <v-form ref="others">
                      <v-text-field
                        outlined
                        dense
                        :rules="rules.emptyField"
                        v-model="othersFileNameLocal"
                      ></v-text-field>
                    </v-form>
                    <v-file-input
                      counter
                      v-model="othersLocal"
                      show-size
                      @change="otherFile"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                </v-flex>
                <!-- display document -->

                <v-flex xs12 sm12 md4 lg4 class="px-1 mt-3 mb-3">
                  <span
                    class="body-2"
                    v-if="register.employmentDocuments.length > 0"
                    >{{ $t("uploaded documents") }}</span
                  >
                  <v-btn
                    x-small
                    color="error darken-2"
                    @click="confirmDelete()"
                    v-if="register.employmentDocuments.length > 0"
                    text
                    style="float: right"
                    >{{ $t("clear documents") }}</v-btn
                  >
                  <div v-if="register.employmentDocuments.length > 0">
                    <v-btn
                      block
                      text
                      class="grey lighten-3 my-1"
                      v-for="documents in register.employmentDocuments"
                      :key="documents.documentType"
                    >
                      <v-layout row wrap>
                        <v-flex xs8 sm8 md8 lg8>
                          {{ documents.documentType }}
                        </v-flex>
                        <v-flex xs2 sm2 md2 lg2>
                          <v-btn
                            x-small
                            right
                            color="info"
                            @click="
                              documentView(
                                documents.documentType,
                                documents.base64
                              )
                            "
                            >{{ $t("view") }}</v-btn
                          >
                        </v-flex>
                        <v-flex xs2 sm2 md2 lg2>
                          <v-btn
                            x-small
                            dark
                            right
                            color="red"
                            @click="
                              {
                                {
                                  confirmDeleteIndividualDocument(
                                    documents.documentType
                                  );
                                }
                              }
                            "
                            >{{ $t("delete") }}</v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-btn>
                  </div>
                  <div v-else>
                    <p class="caption text-center text--disabled">
                      {{ $t("no documents uploaded") }}
                    </p>
                  </div>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-form>
    <v-dialog
      v-model="documentsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <EmploymentDocuments
        :documentType="this.documentType"
        :base64="this.base64"
        @documentsDialog="documentsDialog = false"
      ></EmploymentDocuments
    ></v-dialog>

    <v-dialog v-model="clearDocumentsDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t("clear documents") }}
        </v-card-title>
        <v-card-text
          >{{
            $t("are you sure you want to clear the documents list")
          }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="clearDocumentsDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="red darken-1" text @click="clearDocuments()">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteIndividualDocumentsDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t("delete document") }}
        </v-card-title>
        <v-card-text
          >{{ $t("are you sure you want to delete") }}
          <span class="font-weight-bold">{{ documentType }}</span>
          {{ $t("from the documents list") }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteIndividualDocumentsDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteItem()">
            {{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbarError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not update employee. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackbarError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="success lighten-5 success--text text--darken-3"
      top
      center
      v-model="snackbar"
    >
      {{ $t("employee details successfully updated") }}!
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="success darken-3"
          v-bind="attrs"
          @click="snackbar = false"
          :timeout="timeout"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="success lighten-5 success--text text--darken-3"
      top
      center
      v-model="snackDocumentUploaded"
    >
      {{ documentType }}{{ $t("uploaded successfully") }} !
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="success darken-3"
          v-bind="attrs"
          @click="snackDocumentUploaded = false"
          :timeout="timeout"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarErrorFetch"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not get employee details. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackbarErrorFetch = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <div v-show="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import fb from "firebase/app";
import countryList from "@/data/countries";
import EmploymentDocuments from "@/components/templates/EmploymentDocuments";
import PleaseWait from "@/components/templates/PleaseWait";

export default {
  components: {
    EmploymentDocuments,
    PleaseWait,
  },
  data: () => ({
    file: "",
    base64: "",
    workingVisa: "",
    nationalId: "",
    cvLocal: "",
    cvForeigner: "",
    othersFileNameLocal: "",
    othersLocal: "",
    othersFileNameForeigner: "",
    othersForeigner: "",
    documentType: "",
    loading: false,
    snackbar: false,
    snackbarError: false,
    snackDocumentUploaded: false,
    snackbarErrorFetch: false,
    foreigner: false,
    pleaseWait: true,
    timeout: 2000,
    provinces: [],
    towns: [],
    clearDocumentsDialog: false,
    deleteIndividualDocumentsDialog: false,
    documentsDialog: false,
    shifts: [],
    territories: [],
    selectedForeignerItem: 0,
    selectedLocalItem: 0,
    LocalItems: [
      { text: "National ID", icon: "mdi-badge-account-horizontal-outline" },
      { text: "CV", icon: "mdi-book-open-page-variant-outline" },
      { text: "Others", icon: "mdi-briefcase-plus" },
    ],
    ForeignerItems: [
      { text: "Working Visa", icon: "mdi-badge-account-horizontal-outline" },
      { text: "CV", icon: "mdi-book-open-page-variant-outline" },
      { text: "Others", icon: "mdi-briefcase-plus" },
    ],
    countryList,
    register: null,

    roles: ["Cashier", "Shop attendant", "Waiter", "Manager", "Chef", "Other"],

    rules: {},
  }),

  created() {
    this.getEmployeeDetails();
    this.getShifts();
  },

  computed: {
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    imageName() {
      return this.register.businessId + "/" + this.register.employeeId;
    },
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    countries() {
      return this.countryList.map((item) => item.country);
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || "Please enter a valid email",
        ],
        gender: [(v) => (v || "").length > 0 || "Please select one"],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  methods: {
    getShifts() {
      this.loading = true;
      this.shifts = [];
      if (this.currentBusiness) {
        db.collection("shifts")
          .where("businessId", "==", this.currentBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
        this.loading = false;
      } else {
        db.collection("shifts")
          .where("businessId", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
        this.loading = false;
      }
    },

    previewPicture(event) {
      this.file = event.target.files[0];
      this.register.avatar = URL.createObjectURL(this.file);
    },

    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.register = doc.data();
          this.pleaseWait = false;
        })
        .catch(() => {
          this.pleaseWait = false;

          this.snackbarErrorFetch = true;
        });
    },

    setShiftDetails(id) {
      console.log(id);
      //set supplier id to product details
      let shift = this.shifts.find((x) => x.id === id.id);

      this.register.shiftId = shift.id;
    },

    documentView(documentType, base64) {
      this.documentType = documentType;
      this.base64 = base64;
      this.documentsDialog = true;
    },
    base64WorkingVisa(event) {
      if (this.register.employmentDocuments) {
        var accountIndex = this.register.employmentDocuments.findIndex(
          (item) => item.documentType == "workingVisa"
        );
        if (accountIndex == -1) {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments.push({
              documentType: "workingVisa",
              base64: reader.result,
            });
            this.documentType = "workingVisa";
            this.snackDocumentUploaded = true;
          };

          reader.readAsDataURL(file);
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments[accountIndex] = {
              documentType: "workingVisa",
              base64: reader.result,
            };
            this.documentType = "workingVisa";
            this.snackDocumentUploaded = true;
          };

          reader.readAsDataURL(file);
        }
      } else {
        let file = event;

        const reader = new FileReader();

        reader.onloadend = () => {
          this.register.employmentDocuments.push({
            documentType: "workingVisa",
            base64: reader.result,
          });
          this.documentType = "workingVisa";
          this.snackDocumentUploaded = true;
        };

        reader.readAsDataURL(file);
      }
    },
    base64CV(event) {
      if (this.foreigner) {
        if (this.register.employmentDocuments) {
          var accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == "cvForeigner"
          );
          if (accountIndex == -1) {
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments.push({
                documentType: "cvForeigner",
                base64: reader.result,
              });
              this.documentType = "cvForeigner";
              this.snackDocumentUploaded = true;
            };

            reader.readAsDataURL(file);
          } else {
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments[accountIndex] = {
                documentType: "cvForeigner",
                base64: reader.result,
              };
              this.documentType = "cvForeigner";
              this.snackDocumentUploaded = true;
            };

            reader.readAsDataURL(file);
          }
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            //data.base64 = reader.result;
            this.register.employmentDocuments.push({
              documentType: "cvForeigner",
              base64: reader.result,
            });
            this.documentType = "cvForeigner";
            this.snackDocumentUploaded = true;
          };

          reader.readAsDataURL(file);
        }
      } else {
        if (this.register.employmentDocuments) {
          accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == "cvLocal"
          );

          if (accountIndex == -1) {
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments.push({
                documentType: "cvLocal",
                base64: reader.result,
              });
              this.documentType = "cvLocal";
              this.snackDocumentUploaded = true;
            };

            reader.readAsDataURL(file);
          } else {
            console.log("new");
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments[accountIndex] = {
                documentType: "cvLocal",
                base64: reader.result,
              };
              this.documentType = "cvLocal";
              this.snackDocumentUploaded = true;
            };
            reader.readAsDataURL(file);
          }
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            //data.base64 = reader.result;
            this.register.employmentDocuments.push({
              documentType: "cvLocal",
              base64: reader.result,
            });

            this.documentType = "cvLocal";
            this.snackDocumentUploaded = true;
          };
          reader.readAsDataURL(file);
        }
      }
    },
    base64NationalId(event) {
      if (this.register.employmentDocuments) {
        var accountIndex = this.register.employmentDocuments.findIndex(
          (item) => item.documentType == "National Id"
        );
        if (accountIndex == -1) {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments.push({
              documentType: "National Id",
              base64: reader.result,
            });
            this.documentType = "National Id";
            this.snackDocumentUploaded = true;
          };

          reader.readAsDataURL(file);
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments[accountIndex] = {
              documentType: "National Id",
              base64: reader.result,
            };

            this.documentType = "National Id";
            this.snackDocumentUploaded = true;
          };

          reader.readAsDataURL(file);
        }
      } else {
        let file = event;

        const reader = new FileReader();

        reader.onloadend = () => {
          this.register.employmentDocuments.push({
            documentType: "National Id",
            base64: reader.result,
          });
          this.documentType = "National Id";
          this.snackDocumentUploaded = true;
        };

        reader.readAsDataURL(file);
      }
    },
    otherFile(event) {
      if (this.foreigner) {
        if (this.register.employmentDocuments) {
          var accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == this.othersFileNameForeigner
          );

          console.log(accountIndex);

          if (accountIndex == -1) {
            if (this.$refs.others.validate()) {
              let file = event;
              const reader = new FileReader();

              reader.onloadend = () => {
                this.register.employmentDocuments.push({
                  documentType: this.othersFileNameForeigner,
                  base64: reader.result,
                });
                this.documentType = this.othersFileNameForeigner;
                this.snackDocumentUploaded = true;
              };
              reader.readAsDataURL(file);
            }
          } else {
            if (this.$refs.others.validate()) {
              let file = event;
              const reader = new FileReader();

              reader.onloadend = () => {
                this.register.employmentDocuments[accountIndex] = {
                  documentType: this.othersFileNameForeigner,
                  base64: reader.result,
                };
                this.documentType = this.othersFileNameForeigner;
                this.snackDocumentUploaded = true;
              };
              reader.readAsDataURL(file);
            }
          }
        } else {
          if (this.$refs.others.validate()) {
            let file = event;
            const reader = new FileReader();

            reader.onloadend = () => {
              this.register.employmentDocuments.push({
                documentType: this.othersFileNameForeigner,
                base64: reader.result,
              });
              this.documentType = this.othersFileNameForeigner;
              this.snackDocumentUploaded = true;
            };
            reader.readAsDataURL(file);
          }
        }
      } else {
        if (this.register.employmentDocuments) {
          accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == this.othersFileNameLocal
          );

          console.log(accountIndex);

          if (accountIndex == -1) {
            if (this.$refs.others.validate()) {
              let file = event;
              const reader = new FileReader();

              reader.onloadend = () => {
                this.register.employmentDocuments.push({
                  documentType: this.othersFileNameLocal,
                  base64: reader.result,
                });
                this.documentType = this.othersFileNameLocal;
                this.snackDocumentUploaded = true;
              };
              reader.readAsDataURL(file);
            }
          } else {
            let file = event;
            const reader = new FileReader();

            reader.onloadend = () => {
              this.register.employmentDocuments[accountIndex] = {
                documentType: this.othersFileNameLocal,
                base64: reader.result,
              };
              this.documentType = this.othersFileNameLocal;
              this.snackDocumentUploaded = true;
            };
            reader.readAsDataURL(file);
          }
        } else {
          let file = event;
          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments.push({
              documentType: this.othersFileNameLocal,
              base64: reader.result,
            });
            this.documentType = this.othersFileNameLocal;
            this.snackDocumentUploaded = true;
          };
          reader.readAsDataURL(file);
        }
      }
    },

    confirmDelete() {
      this.clearDocumentsDialog = true;
    },
    clearDocuments() {
      this.register.employmentDocuments = [];
      this.clearDocumentsDialog = false;
    },
    confirmDeleteIndividualDocument(documentType) {
      this.documentType = documentType;
      this.deleteIndividualDocumentsDialog = true;
    },

    deleteItem() {
      var accountIndex = this.register.employmentDocuments.findIndex(
        (item) => item.documentType == this.documentType
      );

      if (accountIndex != -1) {
        this.deleteIndividualDocumentsDialog = false;
        this.register.employmentDocuments.splice(accountIndex, 1);
      }
    },

    updateEmployee() {
      if (this.file != "") {
        this.loading = true;
        let uploadTask = fb
          .storage()
          .ref("employees/" + this.imageName)
          .put(this.file);

        uploadTask.on(
          "state_changed",
          () => {},
          () => {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
              this.register.avatar = downloadLink;

              db.collection("employees")
                .doc(this.$route.params.id)
                .update(this.register)
                .then(() => {
                  this.loading = false;
                  this.file = "";
                  this.snackbar = true;
                  setTimeout(() => this.goBack(), 2000);
                })
                .catch((error) => {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      } else {
        this.loading = true;
        db.collection("employees")
          .doc(this.$route.params.id)
          .update(this.register)
          .then(() => {
            this.loading = false;
            this.file = "";
            this.snackbar = true;
            setTimeout(() => this.goBack(), 2000);
          })
          .catch(() => {
            this.snackbarError = true;
            this.loading = false;
          });
      }
    },

    selectCountry() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates
    },

    selectCounty() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates

      let i = region.findIndex(
        (x) => x.province == this.register.address.county
      );
      this.towns = region[i].towns;
      this.territories = region[i].territories;
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
